h1, h2, h3, h4, h5 {
  font-family: 'PT Sans', sans-serif;
}

.App {
  padding-bottom: 100px;
}

.nav-item {
  height: 50%;
  padding: 10px 5px;
  display: inline-block;
  width: 100;
  height: 100%;
  padding: 10px;
  text-align: center; 
  text-decoration: none !important;
  color: #333 !important;
  transition: all .3s ease-out;
}

.nav-item.active {
  background: #ddd;
}

.nav-item:hover {
  background: #bbb;
}

.points .points-label {
  margin-left: 5px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  color: #0074bb;
  text-align: right;
}

.points svg {
  color: #0074bb;
  text-align: right;
}

.shop-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  align-items: stretch;
}

.shop-item {
  width: 25%;
  padding: 0 10px;
}

.cart-item {
  margin-bottom: 15px;
  height: 200px;
}

.cart-badge {
  font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
  margin-top: -6px;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
}

.btn-cart {
  box-shadow: none !important;
  transition: all .3s ease-out !important;
}

.btn-cart:hover {
  transform: scale(1.1);
}

.btn-toolbar > * {
  margin-right: 10px;
}

.remove-product-btn {
  margin-left: 10px;
}
